import React from "react";
import * as styles from "./style.module.scss";

function index() {
  return (
<div className={styles.presentation}>

    <div className={styles.presentationPortal}>
      
      <div className={styles.content}>
        <div className={styles.desc}>
          <h1 style={{color: "white !important"}}>Custom Projects</h1>
          <p>● 3D and 2D visuals </p>
          <p>● Harmonizing presentations</p>
          <p>● Web and Social Media material</p>
          <p>● Master presentations decks</p>
          <p>● Content revisions</p>
          <p>● Business plans</p>
          <p>● Pitch material</p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default index;



