import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import ServicesPart from "../components/molecules/Services&Info/services";
import FreeTrial from "../components/atoms/freeTrial";
import Head from "../components/ogranisms/head";
import Recommendedpresentations from "../components/molecules/presentationPortalPage/recommendedPresentations";
import AOS from "aos";
import "aos/dist/aos.css";
import Communications from "../components/molecules/hr/communications";
import FlexBanner from "../components/atoms/FlexBanner/FlexBanner";
import ServicesCards from "../components/molecules/ServicesCards/ServicesCards";
function Services() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="Services & Info"
        description="All in one
        All your graphic and communication services in one place to help coordinate your pitch effort and promote greater use and consistency."
        ogTitle="Services & Info"
      />
      <main>
        <Header />
        {/* <FlexBanner
          extraClass={"servicesSection"}
          title={`Creative and Strategic Services `}
          bgImage="https://wizrconnect.com/static/media/bg17-update1.0d680cb0.png"
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680387665/web%20images/Usercases_11_2_1_j3jli2.png"
        /> */}
        <ServicesCards />
        {/* <ServicesPart /> */}
        {/* <Recommendedpresentations /> */}
        {/* <Communications/> */}
        {/* <FreeTrial />
          <Footer/> */}
      </main>
    </>
  );
}

export default Services;
