import React from "react";
import * as styles from "./ServicesCards.module.scss";

export default function ServicesCards() {
  const cards = [
    {
      top: "Most affordable",
      title: "Mini Starter Package",
      subtitle: "Value program delivered in 14 days",
      features: [
        "1 Show Room",
        "1 Portal visualization",
        "50 background visuals",
        "1 presentation template",
        "3 virtual backgrounds",
      ],
      price: "$400 one-time fee (50 % refund if not satisfied)",
      license: "+Enterprise Licenses",
    },
    {
      top: "Recommended",
      title: "Full Starter package",
      subtitle:
        "Mini Starter package + <br /> Services for 3 months <br /> Active follow-up and support:",
      features: [
        "1-2 full days first month",
        "2 meeting follow-ups a month",
        "2-4 internal contact people ",
        "Custom designs assist",
        "Tech input assist",
      ],
      price: "$1500, one off payment",
      license: "+Enterprise Licenses ",
      bgColor:
        "linear-gradient(0deg, rgba(208, 89, 15, 0.53), rgba(38, 37, 37, 0.38))",
      extraClass: "recommended",
    },
    {
      top: "Custom",
      title: "White Label Video",
      subtitle: "Delivered within 14 days",
      features: [
        "Note: it is optimized for web, and big screens (works on mobile, but not perfect)",
      ],
      price: "Price $2500 one-off payment",
      license: "+Enterprise Licenses ",
      extraClass: "label",
    },
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.mainTitle}>Service Offerings</h1>
      <div className={styles.cards}>
        {cards.map((item, i) => (
          <div
            className={`${styles.cardContainer} ${styles[item?.extraClass]}`}
          >
            <div
              style={{ background: item?.bgColor }}
              key={i}
              className={styles.card}
            >
              <h3 className={styles.top}>{item?.top}</h3>
              <h1 className={styles.title}>{item.title}</h1>
              <h4
                className={styles.subtitle}
                dangerouslySetInnerHTML={{ __html: item.subtitle }}
              ></h4>
              <ul>
                {item.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
                <li>{item.price}</li>
                <li>{item.license}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
      <p className={styles.bottomText}>We have a designated service focused web page, check out <a onClick={() => window.open("https://wizr.us/", "_blank")}>wizr.us</a></p>
    </div>
  );
}
