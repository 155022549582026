// extracted by mini-css-extract-plugin
export var bottomText = "ServicesCards-module--bottomText--d51dd";
export var card = "ServicesCards-module--card--5688e";
export var cardContainer = "ServicesCards-module--cardContainer--9bd19";
export var cards = "ServicesCards-module--cards--7cbcb";
export var container = "ServicesCards-module--container--a8753";
export var label = "ServicesCards-module--label--7716e";
export var mainTitle = "ServicesCards-module--mainTitle--6a8a2";
export var recommended = "ServicesCards-module--recommended--6fa8e";
export var subtitle = "ServicesCards-module--subtitle--54f6f";
export var title = "ServicesCards-module--title--59882";
export var top = "ServicesCards-module--top--ac935";